import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBzhq1FzVayu-zLGzzL4rtDBxO5GfX8F7Q",
  authDomain: "wurklo-web-app.firebaseapp.com",
  projectId: "wurklo-web-app",
  storageBucket: "wurklo-web-app.appspot.com",
  messagingSenderId: "166955812347",
  appId: "1:166955812347:web:008476ce0ce2b58d79375e",
  measurementId: "G-RYCZQXXK80"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const appleAuthProvider = new firebase.auth.OAuthProvider('apple.com');
appleAuthProvider.addScope('email')
appleAuthProvider.addScope('name')

const microsoftAuthProvider = new firebase.auth.OAuthProvider('microsoft.com');
const yahooAuthProvider = new firebase.auth.OAuthProvider('yahoo.com');
const githubAuthProvider = new firebase.auth.GithubAuthProvider();



export { db, auth, storage, googleAuthProvider, appleAuthProvider, microsoftAuthProvider, yahooAuthProvider, githubAuthProvider };